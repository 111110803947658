// Parsing runtime configuration. The source file can be found in the public folder.
// Also note that during deployment using docker this file will be regenerated based on server configuration.
// Check public/pub.env.js and Dockerfile Entrypoint for more details
export const backendUrl = (window as any).env?.apiEndpoint
export const sentryEndpoint = (window as any).env?.sentryEndpoint

export const ssoAzure = {
    clientId: (window as any).env?.azureClientId,
    tenantId: (window as any).env?.azureTenantId,
}
