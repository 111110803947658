import { createTheme } from "@material-ui/core/styles"

const main = createTheme({
  palette: {
    primary: {
      main: "#a81c2c"
    },
    secondary: {
      main: "#9c9cfd"
    }
  },
  typography: {
    fontFamily: ["Catamaran", "sans-ser if"].join(","),

  }
})

const exportTheme = {
  main,
  graphFills: ["#a81c2c", "#921725", "#7c121e", "#670d17", "#520811", "#3f050a", "#2c0205", "#1a0102", "#0a0001", "#010000"]
}

export default exportTheme;
