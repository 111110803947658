import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react'
import { useText } from './currentLanguageContext';

const useStyle = makeStyles({
    wrapper: {
        position: 'fixed',
        bottom: 0,
        right: '50%',
        transform: 'translateX(50%)',
        zIndex: 0,
        overflow: 'hidden',
        maxHeight: 0,
        transition: 'all 0.5s',
    },
    showWrapper: {
        maxHeight: '10%',
        zIndex: 1000,
    },
    container: {
        background: 'var(--light-background)',
        borderRadius: '999px 999px 0px 0px',
        padding: '10px 30px 5px 30px',
        transition: 'up 0.5s',
        position: 'relative',
        whiteSpace: 'nowrap',
    },
});

interface SaveToastContext {
    startSaving: () => number
    markSaved: (id?: number) => void
}

interface Props {
    children: any
}

const saveToastContext = React.createContext<SaveToastContext>(null);

export const useSaveToast = () => React.useContext(saveToastContext);

let currentSaveId = 0;
const TIMEOUT = 3000;

export default function SaveToastContextProvider({ children }: Props) {
    const classes = useStyle();
    const text = useText();
    const [currentState, setCurrentState] = useState({ saving: false, saved: false });

    const label = currentState.saving ? text.saving : `${text.saved} ✓`;

    const startSaving = () => {
        setCurrentState({ saving: true, saved: false })
        return ++currentSaveId;
    };

    const markSaved = async (myId?: number) => {
        if (myId && myId !== currentSaveId) {
            return;
        }
        setCurrentState({ saving: false, saved: true })

        await new Promise((resolve) => setTimeout(resolve, TIMEOUT));
        if (myId && myId !== currentSaveId) {
            return;
        }

        setCurrentState({ saving: false, saved: false })
    }

    const isVisible = currentState.saving || currentState.saved;

    return (
        <saveToastContext.Provider value={{ startSaving, markSaved }}>
            <div className={`${classes.wrapper} ${isVisible ? classes.showWrapper : 'display-none'}`}>
                <div className={`highlight-color bold ${classes.container}`}>
                    {label}
                </div>
            </div>
            {children}
        </saveToastContext.Provider>
    )
}