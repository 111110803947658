import React from "react";

interface UserTokenContext {
    token: string,
    setToken: (token: string) => void,
}

const TOKEN_KEY = 'token';

const userTokenContext = React.createContext<UserTokenContext>({ token: '', setToken: () => { } });

export const useUserToken = () => React.useContext(userTokenContext);

interface Props {
    children: React.ReactElement
}

const UserTokenProvider = ({ children }: Props) => {
    const [token, setToken] = React.useState(window.localStorage[TOKEN_KEY]);

    const updateLocalStorageAndState = (newToken: string) => {
        window.localStorage.setItem(TOKEN_KEY, newToken)
        setToken(newToken)
    }


    return (
        <userTokenContext.Provider value={{ token, setToken: updateLocalStorageAndState }}>
            {children}
        </userTokenContext.Provider>
    )
}

export default UserTokenProvider;