import * as React from "react"
import { CurrentLanguageContext } from "src/context/currentLanguageContext"

import base from "../resources/text/base"
import base_en from "../resources/text/base_en"

const variants = {
  base: {
    ENGLISH: base_en
  },
}

export interface Props {
  language?: string
}
export interface State { }

class LanguageContextComponent extends React.Component<
  Props,
  State
> {
  getValue() {
    const language = this.props.language || "SWEDISH"
    let value = base

    const searchParams = new URLSearchParams(window.location.search);
    const debug = searchParams.get('debug');
    if (debug) {
      const keys = { ...value };

      for (const key of Object.keys(keys)) {
        value[key] = 'DEBUG_' + key;
      }
    }
    if (debug === 'all') {
      return value;
    }

    if (language in variants.base) {
      value = { ...value, ...variants.base[language] }
    }

    return value
  }

  render() {
    return (
      <CurrentLanguageContext.Provider value={this.getValue()}>
        {this.props.children}
      </CurrentLanguageContext.Provider>
    )
  }
}

export default LanguageContextComponent
