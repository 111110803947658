import * as React from "react"
export default function asyncComponent(getComponent: any) {
  class AsyncComponent extends React.Component {
    static Component = null
    state = { Component: AsyncComponent.Component }

    UNSAFE_componentWillMount() {
      if (!this.state.Component) {
        getComponent().then((Component: any) => {
          AsyncComponent.Component = Component.default
          this.setState({ Component: Component.default })
        })
      }
    }
    render() {
      const { Component }: any = this.state
      if (Component) {
        return <Component {...this.props} />
      }
      return null
    }
  }
  return AsyncComponent
}
